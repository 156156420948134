import enAsset from './en.json'
import koAsset from './ko.json'

export const defaultNS = 'common';

export const resources = {
    en: {
        common: enAsset,
    },
    ko: {
        common: koAsset,
    },
} as const;

export default resources;