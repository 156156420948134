import React from 'react';
import { useTranslation } from 'react-i18next';

export const Localize = () => {
    const { t, i18n } = useTranslation();

    const handleLanguageSelectChange: React.ChangeEventHandler<HTMLSelectElement> =
        (event) => {
            i18n.changeLanguage(event.target.value);
        };

    const value = ['en', 'ko'].find((language) =>
        i18n.language.startsWith(language)
    );

    return (
        <div>
            <div>
                <div>{t('header')}</div>
                <div>{t('description')}</div>
            </div>
                <select value={value} onChange={handleLanguageSelectChange}>
                    <option value="en">English</option>
                    <option value="ko">한국어</option>
                </select>
        </div>
    );
};