import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { defaultNS, resources } from '../../assets/i18n';

// initialize i18n
i18n
    .use(initReactI18next)
    .init({
        ns: ['common'],
        defaultNS,
        resources,
        supportedLngs: ['en', 'ko'],
        fallbackLng: 'en',
    });

export default i18n;
